(function($) {
  function Slides() {
    var _ = this;

    $(".slider-home").slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      fade: true,
      autoplay: true,
      autoplaySpeed: 3500
    });
  }

  new Slides();
})(jQuery);
